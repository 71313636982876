// make these read from a .env file?
export const DEBUG = false;
export const DEBUG_PREFILL_ORDER = false;
export const LOCAL_MODE = false;

let APIConfig;

if (DEBUG) {
  APIConfig = {
    //change this for production or for ngrok testing
    DJANGO_API_BASE_URL: LOCAL_MODE ? 'http://127.0.0.1:8002/api/v1' : 'https://backend.dev.bitbenders.com/api/v1',
    // don't show stripe test public key for security purposes
    STRIPE_PUBLIC_KEY_TUCSON: 'pk_test_wX7lNUdgHMSHTqyoXiJjaA0U00pweGNRpX',
    STRIPE_PUBLIC_KEY_AUSTIN: 'pk_test_51MCw9iGXp4jbJeqhvkqmlC5SXIHs5RmxPk0o0qeVc7Qkrl3GHZAFmfqAqk8RIWGs8UIwKAUngINlZIbYaXXbDdcl00MNIh0jX6'
  };
} else {
  APIConfig = {
    //change this for production or for ngrok testing
    DJANGO_API_BASE_URL: 'https://backend.sendystorage.com/api/v1',
    STRIPE_PUBLIC_KEY_TUCSON: 'pk_live_51QwaLJLmfuTow9ZyNrRDgw6jxGaAkoshRtTrOWGqncpLzZtTww2LqLNvAebr7Vmn4cV6UUOHGeNVLthyWqr5KRod00GOzekFr5',
    STRIPE_PUBLIC_KEY_AUSTIN: 'pk_live_51MCw9iGXp4jbJeqhPwxhH6ofu1PH52IxFEtRDebWDE8aNz2MaGtxA9IHFTNIrX0tidUib19B0tXt6Fxti9QYlpQt00gg7WP4ha'
  };

  // // disable console.logs
  if(!window.console) window.console = {};
  var methods = ["log", "debug", "warn", "info"];
  for(var i=0;i<methods.length;i++){
    console[methods[i]] = function(){};
  }
}

export default APIConfig;
