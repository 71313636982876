import React, { useEffect, useState } from 'react';
import {
  withStyles,
  Typography,
} from '@material-ui/core';
import FooterStyle from './FooterStyle';
import { Link } from 'react-router-dom';

import logoNav from '../../../assets/img/shared/logo-nav.png';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import googleImg from '../../../assets/img/shared/google.png';
import googlePlayImg from '../../../assets/img/shared/google_play.png';
import appleStoreImg from '../../../assets/img/shared/apple_store.jpg';
import FooterModal from '../FooterModal/FooterModal';

//pdfs
import coverage_plan_pdf from '../../../assets/pdf/coverage_plan.pdf';
import jobs_pdf from '../../../assets/pdf/jobs.pdf';
import privacy_policy_pdf from '../../../assets/pdf/privacy_policy.pdf';
import service_areas_pdf from '../../../assets/pdf/service_areas.pdf';
import standard_operating_procedures_pdf from '../../../assets/pdf/standard_operating_procedures.pdf';
import terms_and_conditions_pdf from '../../../assets/pdf/terms_and_conditions.pdf';

const Footer = (props) => {
  const { classes } = props;
  const [pdfData, setPdfData] = useState(null);
  const [openFooterModal, setOpenFooterModal] = useState(false);

  useEffect(() => {
    if(pdfData) {
      setOpenFooterModal(true);
    }
  }, [pdfData]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={`${classes.col1} ${classes.col}`}>
            <img src={logoNav} alt={"Sendy Storage Logo"} className={classes.logo}/>

            <Typography variant={'subtitle1'}>
              <strong>Tucson</strong> (520) 276-6906
            </Typography>

            <Typography variant={'subtitle1'}>
              <strong>Austin</strong> (512) 814-5669
            </Typography>

            <Typography variant={'h4'} className={classes.followUs}>
              Follow Us
            </Typography>

            <div className={classes.socialContainerWrapper}>
              <a href={'https://instagram.com/sendystorage?igshid=1wfpe7yregu3o'} className={classes.socialContainer}>
                <InstagramIcon/>
              </a>

              <a href={'https://www.facebook.com/SendyStorage/'} className={classes.socialContainer}>
                <FacebookIcon/>
              </a>

              <a href={"https://g.page/sendystorage?share"} className={classes.socialContainer}>
                <img src={googleImg} alt={"Google"} className={classes.socialContainerImg} style={{width: '26px', height: '26px'}}/>
              </a>
            </div>

            <a
              className={classes.appStoreButtonContainer}
              style={{marginTop: '20px'}}
              href={'https://play.google.com/store/apps/details?id=com.sendystoragern'}
            >
              <img src={googlePlayImg} alt={"Get our app on Google Play!"} className={classes.appStoreButton}/>
            </a>

            <a
              className={classes.appStoreButtonContainer}
              href={'https://apps.apple.com/us/app/sendy-storage/id1507719209'}
            >
              <img src={appleStoreImg} alt={"Get our app on Apple App Store!"} className={classes.appStoreButton} style={{height: '75px'}}/>
            </a>
          </div>

          <div className={`${classes.col2} ${classes.col}`}>
            <Typography
              variant={'h6'}
              style={{marginTop: '0px'}}
            >
              Service Areas:
            </Typography>

            <div className={classes.serviceAreaOuterContainer}>
              <div className={classes.serviceAreaInnerContainer}>
                <div className={classes.serviceAreaListContainer}>
                  <Typography variant={'body1'} className={classes.smallText}>
                    - Tucson, AZ
                  </Typography>
                  <Typography variant={'body1'} className={classes.smallText}>
                    - Austin, TX
                  </Typography>

                  <div className={classes.comingSoon}>
                    <Typography variant={'body1'} className={classes.smallText}>
                      Want Sendy Storage in<br/>your city?
                      Reach out here: info@sendystorage.com
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${classes.col3} ${classes.col}`}>
            <Typography
              variant={'h6'}
              className={classes.linkText}
              onClick={() => setPdfData({
                file: standard_operating_procedures_pdf,
                title: 'Standard Storage and Operating Procedures'
              })}
            >
              Standard Storage Operating Procedures
            </Typography>

            <Typography
              variant={'h6'}
              className={classes.linkText}
              onClick={() => setPdfData({
                file: coverage_plan_pdf,
                title: 'Insurance and Coverage Info'
              })}
            >
              Insurance and Coverage Info
            </Typography>

            <Typography
              variant={'h6'}
              className={classes.linkText}
              style={{marginTop: '0px'}}
              onClick={() => setPdfData({
                file: terms_and_conditions_pdf,
                title: 'Terms and Conditions'
              })}
            >
              Terms and Conditions
            </Typography>

            <Typography
              variant={'h6'}
              className={classes.linkText}
              onClick={() => setPdfData({
                file: privacy_policy_pdf,
                title: 'Privacy Policy'
              })}
            >
              Privacy Policy
            </Typography>

            <Typography
              variant={'h6'}
              className={classes.linkText}
              onClick={() => setPdfData({
                file: jobs_pdf,
                title: 'Jobs'
              })}
            >
              Jobs
            </Typography>
          </div>

          <div className={`${classes.col4} ${classes.col}`}>
            <Link to={'/order/storage'}>
              <Typography
                variant={'h6'}
                className={classes.linkText}
              >
                Storage
              </Typography>
            </Link>

            <Link to={'/faq'} className={classes.linkElem}>
              <Typography variant={'h6'} className={classes.linkText}>
                FAQs
              </Typography>
            </Link>

            <Link to={'/about'} className={classes.linkElem}>
              <Typography variant={'h6'} className={classes.linkText}>
                About Us
              </Typography>
            </Link>

            <Link to={'/my-account'}>
              <Typography variant={'h6'} className={classes.linkText}>
                My Account
              </Typography>
            </Link>

            <Typography
              variant={'h6'}
              className={classes.linkText}
              style={{marginTop: '0px'}}
              onClick={() => setPdfData({
                file: service_areas_pdf,
                title: 'Sendy Storage Service Areas'
              })}
            >
              Service Areas
            </Typography>

            {/*<a href={'/sitemap.xml'} className={classes.linkElem}>*/}
            {/*  <Typography variant={'h6'} className={classes.linkText}>*/}
            {/*    Site Map*/}
            {/*  </Typography>*/}
            {/*</a>*/}
          </div>

        </div>

        <Typography variant={'body1'} paragraph align={'center'} className={classes.bottomText}>
          By Using This Site, you agree to be bound by the Sendy Storage Terms and Conditions and to use the Site in accordance with these Terms and Conditions
        </Typography>

        <Typography variant={'body1'} align={'center'} className={classes.bottomText}>
          ©2019 Send It Storage, LLC. Tucson, Arizona{' '}<br/>
          Sendy Storage® is a registered trademark of Send It Storage, LLC DBA Sendy Storage{' '}<br/>
          Sendy Storage Tucson operated by Sendy Tucson Storage LLC{' '}<br/>
          Sendy Storage Austin operated by Sendy Storage Austin LLC
        </Typography>
      </div>

      {pdfData &&
        <FooterModal
          open={openFooterModal}
          closeFunc={() => setPdfData(null)}
          title={pdfData.title}
          file={pdfData.file}
        />
      }
    </>
  )
};

export default withStyles(FooterStyle)(Footer);
